import React from 'react';

import { StackProps, Text, VStack } from '@adc/parallax-component-library';
import { IconValidationInvalid } from '@adc/parallax-icons';

type Props = StackProps & {
  message?: string;
  submessage?: string;
};

const Banner = ({ message, submessage, ...rest }: Props) => {
  if (!message && !submessage) {
    return null;
  }

  return (
    <VStack
      background="$surface.container"
      display="flex"
      flexDirection="row"
      borderStyle="solid"
      borderWidth={1}
      borderColor="$neutral.20"
      borderLeftWidth={0}
      borderRightWidth={0}
      padding="$4"
      marginBottom="$2"
      marginTop="$3"
      testID="Banner.wrapper"
      {...rest}
    >
      <VStack>
        <IconValidationInvalid
          color="$support.danger.surfaceOnColorAlt"
          testID="Banner.iconError"
        />
      </VStack>
      <VStack marginLeft="$2" display="flex" flex={1}>
        <Text
          testID="Banner.message"
          color="$text.100"
          fontSize="$bodyBase.default"
          fontWeight="$bodyBase.default"
        >
          {message}
        </Text>
        <Text
          testID="Banner.message2"
          color="$text.100"
          fontSize="$bodyBase.default"
          fontWeight="$bodyBase.strong"
        >
          {submessage}
        </Text>
      </VStack>
    </VStack>
  );
};

export { Banner };
