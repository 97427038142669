import React from 'react';

import { LoadingSpinner, VStack } from '@adc/parallax-component-library';

type Props = {
  testID?: string;
};

const Spinner: React.FC<Props> = ({ testID = '' }) => {
  return (
    <VStack
      padding="$4"
      backgroundColor="$surface.background"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      zIndex={1}
      style={{
        height: '100vh',
        width: '100vw',
      }}
    >
      <LoadingSpinner testID={testID} />
    </VStack>
  );
};

export { Spinner };
