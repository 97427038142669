import React from 'react';

import { HStack, Text, VStack } from '@adc/parallax-component-library';
import { IconBack } from '@adc/parallax-icons';

import { IconButton } from 'Components/utility';

import mediator from 'Utilities/mediator';

import { ActionBarHeader } from './ActionBarHeader';

type TitleActionBarProps = {
  titleTestID?: string;
  actionButtonTestID?: string;
  title: string;
  onBack?: () => void;
};

const TitleActionBar: React.FC<TitleActionBarProps> = ({
  titleTestID: testIDTitle,
  actionButtonTestID: testIDActionButton,
  title,
  onBack,
}) => {
  return (
    <VStack
      style={{
        top: 0,
        left: 0,
        right: 0,
      }}
      backgroundColor="$surface.container"
    >
      <ActionBarHeader />
      <HStack padding="$4">
        <IconButton
          testID={testIDActionButton}
          onPress={() => {
            onBack ? onBack() : mediator.publish('router:back');
          }}
        >
          <IconBack testID={`${testIDActionButton}-icon`} />
        </IconButton>
        <Text
          testID={testIDTitle}
          color="$text.100"
          fontSize="$h4.strong"
          fontWeight="$h4.strong"
          width="100%"
          alignSelf="center"
          textAlign="center"
        >
          {title}
        </Text>
      </HStack>
    </VStack>
  );
};

export { TitleActionBar };
