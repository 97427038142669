import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Divider, Stack, Text, VStack } from '@adc/parallax-component-library';

import { Button, Link } from 'Components/utility';

import { FOOTER_SIZE, SCROLL_RESET } from 'Reducers/scrollPage/types';

import { checkBrowser } from 'Utilities/browser';
import { store } from 'Utilities/store';

import { RootState } from 'src/reducers';

const { isAndroid } = checkBrowser();

type FooterProps = {
  buttonText?: string;
  onButtonSubmit?: () => void;
  buttonTestID?: string;
  buttonLinkText?: string;
  onButtonLinkClick?: () => void;
  buttonLinkTitleTestID?: string;
  buttonLinkTestID?: string;
  isButtonDisabled?: boolean;
  buttonLinkTitle?: string;
  customProps?: JSX.Element;
  visible?: boolean;
};

const mapStateToProps = ({ scrollPage }: RootState) => {
  return {
    scrollPage,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & FooterProps;

const Footer: React.FC<Props> = ({
  scrollPage,
  buttonText = '',
  onButtonSubmit,
  buttonTestID: testIDButton,
  isButtonDisabled,
  buttonLinkTitle,
  onButtonLinkClick,
  buttonLinkTitleTestID,
  buttonLinkTestID,
  buttonLinkText,
  customProps,
  visible = true,
}) => {
  const footerRef = useRef<any>();

  const [isVisible, setIsVisible] = useState(visible);

  useEffect(() => {
    if (footerRef.current) {
      store.dispatch({ type: FOOTER_SIZE, footerSize: footerRef.current.clientHeight });

      // This is a hack to prevent the footer from moving to the top of screen when the keyboard is open on Android.
      if (isAndroid) {
        const sumedges = window.innerWidth + window.innerHeight;

        window.visualViewport?.addEventListener('resize', () => {
          const keyboardDidShow = window.innerWidth + window.innerHeight < sumedges;

          setIsVisible(!keyboardDidShow);
        });
      }
    }
  }, []);

  const hasScrollOrBottomedOut = !scrollPage.isActive || scrollPage.bottomedOut;

  const onButtonClick = useCallback(() => {
    onButtonSubmit && onButtonSubmit();

    store.dispatch({ type: SCROLL_RESET });
  }, [onButtonSubmit]);

  const onButtonLink = useCallback(() => {
    onButtonLinkClick && onButtonLinkClick();

    store.dispatch({ type: SCROLL_RESET });
  }, [onButtonLinkClick]);

  if (!isVisible) {
    return null;
  }

  return (
    <VStack
      ref={footerRef}
      padding={4}
      paddingBottom={isAndroid ? '$4' : '$8'}
      alignSelf="flex-end"
      width="100%"
      backgroundColor={
        !scrollPage.isActive || scrollPage.bottomedOut
          ? '$surface.background'
          : '$surface.scrollContainer'
      }
      style={{
        shadowOffset: { width: 0, height: hasScrollOrBottomedOut ? 0 : -4 },
        shadowOpacity: hasScrollOrBottomedOut ? 0 : 0.08,
        shadowRadius: hasScrollOrBottomedOut ? 0 : 12,
      }}
    >
      {customProps ? (
        customProps
      ) : (
        <>
          <Button
            marginHorizontal="$3"
            onPress={onButtonClick}
            testID={testIDButton}
            disabled={isButtonDisabled}
            aria-label={buttonText}
            label={buttonText}
          />
          {onButtonLinkClick && buttonLinkText && buttonLinkTestID && (
            <>
              <Divider marginTop="$5" borderColor="$neutral.40" />
              <Text
                testID={buttonLinkTitleTestID}
                fontFamily="$body"
                fontWeight="$bodySmall.default"
                color="$text.100"
                textAlign="center"
                fontSize="$bodySmall.default"
                marginTop="$4"
                marginBottom="$2"
              >
                {buttonLinkTitle}
              </Text>
              <Link testID={buttonLinkTestID} aria-label={buttonLinkText} onPress={onButtonLink}>
                {buttonLinkText}
              </Link>
            </>
          )}
        </>
      )}
    </VStack>
  );
};

const ConnectedFooter = connector(Footer);

export { ConnectedFooter as Footer };
