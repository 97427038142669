import React, { useCallback } from 'react';

import { Text } from '@adc/parallax-component-library';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import { Footer, Form, Input, Main, Spinner, TitleActionBar } from 'Components/utility';

import i18n from 'Utilities/i18n';
import * as log from 'Utilities/log';

export interface FormValues {
  email: string;
}

type Props = {
  handleSubmitForgotPass(email: string): Promise<void>;
  next: (data: FormValues) => void;
  loading: boolean;
};

const initialValues: FormValues = { email: '' };

const ForgotPassFirstStep: React.FC<Props> = ({ next, handleSubmitForgotPass, loading }) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        i18n.t(
          'ForgotPassword.content.forgotPasswordForm.formField.email.errors.emailAddressValidEmail'
        )
      )
      .required(
        i18n.t('ForgotPassword.content.forgotPasswordForm.formField.email.errors.required')
      ),
  });

  const onSubmit = useCallback(
    async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
      setSubmitting(true);

      try {
        await handleSubmitForgotPass(values.email);
        next(values);
      } catch (err) {
        log.error(err);
      } finally {
        setSubmitting(false);
      }
    },
    [handleSubmitForgotPass, next]
  );

  const { handleSubmit, handleChange, handleBlur, isValid, isSubmitting, errors, touched, values } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
      validateOnBlur: true,
    });

  return (
    <Main>
      <TitleActionBar
        titleTestID="ForgotPass.forgotPassword"
        actionButtonTestID="ForgotPass.step1-go-back"
        title={i18n.t<string>('ForgotPassword.title')}
      />
      {loading && <Spinner />}
      <Text
        testID="ForgotPass.enterEmailUsedInThisAccount"
        textAlign="auto"
        fontSize="$bodyBase.default"
        fontWeight="$bodyBase.default"
        color="$text.100"
        padding="$4"
      >
        {i18n.t<string>('ForgotPassword.subtitle')}
      </Text>
      <Form>
        <Input
          aria-label={i18n.t<string>(
            'ForgotPassword.content.forgotPasswordForm.formField.email.label'
          )}
          placeholder={i18n.t<string>(
            'ForgotPassword.content.forgotPasswordForm.formField.email.placeholder'
          )}
          label={i18n.t<string>('ForgotPassword.content.forgotPasswordForm.formField.email.label')}
          errorMessage={errors.email}
          isInvalid={errors.email && touched.email ? true : false}
          testID="email"
          value={values.email}
          marginTop="$4"
          onChangeText={handleChange('email')}
          onBlur={handleBlur('email')}
          inputMode="email"
        />
      </Form>
      <Footer
        buttonText={i18n.t<string>(
          'ForgotPassword.content.forgotPasswordForm.links.sendToEmail.label'
        )}
        onButtonSubmit={handleSubmit}
        buttonTestID="ForgotPass.sendLinkToEmail"
        isButtonDisabled={!values.email || !isValid || isSubmitting}
      />
    </Main>
  );
};

export default ForgotPassFirstStep;
