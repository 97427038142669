import React from 'react';

import { Text, VStack } from '@adc/parallax-component-library';

import { usePartner } from 'Hooks';

import { Button, Main, ResponsiveContainer } from 'Components/utility';

import i18n from 'Utilities/i18n';

const ResetPasswordSecondStep = () => {
  const { partner } = usePartner();

  const handleBackButtonClick = () => {
    window.location.replace(partner.website);
  };

  return (
    <Main>
      <img
        src={i18n.t<string>('Global.microcopy.common.libreLogo')}
        alt=""
        id="Global.microcopy.common.libreLogo"
        style={{
          position: 'absolute',
          zIndex: 1,
          marginTop: '20px',
          width: '100px',
          height: '50px',
          objectFit: 'contain',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
      <ResponsiveContainer>
        <img
          src={i18n.t<string>(
            'ResetPasswordSuccessful.content.ResetPasswordSuccessfulMessage.media.passwordResetSuccess'
          )}
          alt=""
          id="ResetPasswordSuccessful.content.ResetPasswordSuccessfulMessage.media.passwordResetSuccess"
          style={{
            marginTop: '40px',
            height: '105px',
            objectFit: 'contain',
          }}
        />
        <VStack
          marginTop="$7"
          gridRowGap="$2"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            testID="ResetPasswordSuccessful.content.ResetPasswordSuccessfulMessage.primaryText"
            fontWeight={'$h2.medium'}
            fontSize="$h2.medium"
            color="$text.100"
            display="flex"
            flexDirection="column"
            textAlign="center"
          >
            {i18n.t<string>(
              'ResetPasswordSuccessful.content.ResetPasswordSuccessfulMessage.primaryText'
            )}
          </Text>
          <Text
            testID="ResetPasswordSuccessful.content.ResetPasswordSuccessfulMessage.secondaryText"
            fontWeight="$bodyBase.default"
            fontSize="$bodyBase.default"
            color="$text.80"
            display="flex"
            flexDirection="column"
            textAlign="center"
          >
            {i18n.t<string>(
              'ResetPasswordSuccessful.content.ResetPasswordSuccessfulMessage.secondaryText'
            )}
          </Text>
        </VStack>
        <VStack width="90%" marginHorizontal="auto" marginTop="$12">
          <Button
            onPress={handleBackButtonClick}
            testID="ResetPasswordSuccessful.backToPartner"
            label={i18n.t<string>('ResetPasswordSuccessful.microcopy.ButtonTextSet.backToPartner', {
              partnerName: partner.displayName,
            })}
            aria-label={i18n.t<string>(
              'ResetPasswordSuccessful.microcopy.ButtonTextSet.backToPartner',
              {
                partnerName: partner.displayName,
              }
            )}
          />
        </VStack>
      </ResponsiveContainer>
    </Main>
  );
};

export default ResetPasswordSecondStep;
