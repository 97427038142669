import React from 'react';

import { Stack, StackProps, Text } from '@adc/parallax-component-library';

type HeaderProps = Omit<StackProps, 'testID'> & {
  title: string;
  subtitle?: string;
  testID?: string;
  titleTestID?: string;
  subtitleTestID?: string;
  visible?: boolean;
  textAlign?: 'auto' | 'center';
};

const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  titleTestID,
  subtitleTestID,
  visible = true,
  textAlign,
  ...rest
}) => {
  if (!visible) {
    return null;
  }

  return (
    <Stack paddingHorizontal="$4" {...rest}>
      <Text
        testID={titleTestID}
        textAlign={textAlign}
        fontSize="$h1.strong"
        fontWeight="$h1.strong"
        marginTop="$4"
      >
        {title}
      </Text>
      <Text
        testID={subtitleTestID}
        textAlign={textAlign}
        fontSize="$bodyBase.default"
        fontWeight="$bodyBase.default"
        color="$text.100"
        marginTop="$4"
      >
        {subtitle}
      </Text>
    </Stack>
  );
};

export { Header };
