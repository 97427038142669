import React, { useCallback } from 'react';

import { Text, VStack } from '@adc/parallax-component-library';

import { Footer, Header, LogoActionBar, Main, ScrollContainer } from 'Components/utility';
import { NavigationProps } from 'Components/wizard';

import i18n from 'Utilities/i18n';

type Props = NavigationProps<Register>;

const RegisterMinorIntro: React.FC<Props> = ({ next, back, values }) => {
  const handleSubmit = useCallback(() => {
    next(values);
  }, [next, values]);

  const handleOnBack = useCallback(() => {
    back();
  }, [back]);

  return (
    <Main>
      <LogoActionBar
        actionButtonTestID="CreateAccountIntro.action-go-back"
        titleTestID="CreateAccountIntro.actionTitle"
        title={i18n.t<string>('CreateAccountIntro.title')}
        onBack={handleOnBack}
      />
      <ScrollContainer>
        <Header
          titleTestID="CreateAccountIntro.title"
          title={i18n.t<string>('CreateAccountIntro.title')}
          marginBottom="$4"
        />
        <VStack paddingHorizontal="$4" justifyContent="center" alignItems="center">
          <img
            src={i18n.t<string>('CreateAccountIntro.content.createAccountIntro.media.introMinor')}
            alt=""
            width="218px"
            height="244px"
            id="CreateAccountIntro.image-of-family"
          />
          <Text
            testID="CreateAccountIntro.content.createAccountIntro.primaryText"
            color="$text.100"
            fontWeight="$bodyBase.medium"
            fontSize="$bodyBase.medium"
            marginTop="$5"
          >
            {i18n.t<string>('CreateAccountIntro.content.createAccountIntro.primaryText')}
          </Text>
          <Text
            marginTop="$4"
            testID="CreateAccountIntro.content.createAccountIntro.bodyText"
            color="$text.100"
            fontSize="$bodyBase.default"
            fontWeight="$bodyBase.default"
          >
            {i18n.t<string>('CreateAccountIntro.content.createAccountIntro.bodyText', {
              appName: i18n.t<string>('Global.appNames.libreView'),
            })}
          </Text>
        </VStack>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>('Global.microcopy.common.continue')}
        onButtonSubmit={handleSubmit}
        buttonTestID="CreateAccountIntro.continue-btn"
        isButtonDisabled={false}
      />
    </Main>
  );
};

export default RegisterMinorIntro;
