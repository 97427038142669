import { generateUserAgentHeader, urls } from 'Utilities/dataHelpers';
import * as log from 'Utilities/log';
import mediator from 'Utilities/mediator';

export const registerPatient = (register: Register, app: string) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse>('api:invoke', {
        method: 'POST',
        url: urls.libreSharingApiUrl + 'v1/patients',
        headers: {
          'X-User-Agent': app,
        },
        data: {
          Email: register.email,
          Password: register.password,
          Country: register.country,
          FirstName: register.firstName,
          LastName: register.lastName,
          DateOfBirth: register.dateOfBirth,
          Language: register.language,
          GuardianFirstName: register.guardianFirstName,
          GuardianLastName: register.guardianLastName,
          consents: register.consents,
        },
      })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const updatePassword = (
  updatePasswordData: UpdatePasswordRequest,
  osType: string,
  osVersion: string,
  app: string,
  appVersion: string
) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse>('api:invoke', {
        method: 'PATCH',
        url: `${urls.libreSharingApiUrl}accounts/${updatePasswordData.userID}/password`,
        headers: {
          'X-User-Agent': generateUserAgentHeader(app, appVersion, osType, osVersion),
        },
        data: {
          password: updatePasswordData.newPassword,
          secondaryAuthentication: {
            email: updatePasswordData.emailConfirmation,
            password: updatePasswordData.currentPassword,
          },
        },
      })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const updatePatient = (
  id: string,
  patient: Patient,
  osType: string,
  osVersion: string,
  app: string,
  appVersion: string
) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse<Patient>>('api:invoke', {
        method: 'PUT',
        url: urls.libreSharingApiUrl + 'patients/' + id,
        headers: {
          'X-User-Agent': generateUserAgentHeader(app, appVersion, osType, osVersion),
        },
        data: {
          FirstName: patient.firstName,
          LastName: patient.lastName,
          DateOfBirth: patient.dateOfBirth,
          GuardianFirstName: patient.guardianFirstName,
          GuardianLastName: patient.guardianLastName,
        },
      })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const getPatient = (
  patientId: string,
  osType = 'none',
  osVersion = 'none',
  app = 'none',
  appVersion = 'none'
) => {
  return new Promise<Patient>((resolve, reject) => {
    mediator
      .request<ApiResponse<Patient>>('api:invoke', {
        method: 'GET',
        url: urls.libreSharingApiUrl + 'patients/' + patientId,
        headers: {
          'X-User-Agent': generateUserAgentHeader(app, appVersion, osType, osVersion),
        },
      })
      .then((result) => {
        if (result.data) {
          return resolve(result.data);
        }

        reject();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};
