import React, { useCallback, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Stack } from '@adc/parallax-component-library';
import { IconQuestionmark } from '@adc/parallax-icons';
import { AxiosError } from 'axios';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import { ApiStatus } from 'Enums';

import { useApiError, useAuth } from 'Hooks';

import {
  Banner,
  Button,
  Footer,
  Form,
  Header,
  Input,
  InputPassword,
  Link,
  LogoActionBar,
  Main,
  ScrollContainer,
  Spinner,
} from 'Components/utility';

import { logInfo } from 'Utilities/appCommunicator';
import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';

import { signIn } from 'Services/auth';
import { verifyCid } from 'Services/insulet';

import { RootState } from 'src/reducers';

interface MyFormValues {
  email: string;
  password: string;
}

const initialValues: MyFormValues = {
  email: '',
  password: '',
};

const mapStateToProps = ({
  env: { app, cid, country, redirect_uri },
  nav: { redirection },
}: RootState) => {
  return {
    app,
    cid,
    country,
    redirect_uri,
    redirection,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const Login: React.FC<Props> = ({ app, cid, country, redirect_uri }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { showErrorPage } = useApiError();

  const {
    handleLoginError,
    handleLockoutError,
    handleUnsupportedCountryError,
    handleSessionExpired,
    showHcpError,
    redirect,
    isLocked,
    lockoutMessage,
    lockoutSubmessage,
  } = useAuth(redirect_uri);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        i18n.t<string>('Login.content.signInForm.formField.email.errors.emailAddressValidEmail')
      )
      .required(i18n.t<string>('Login.content.signInForm.formField.email.errors.required')),
    password: Yup.string().required(
      i18n.t<string>('Login.content.signInForm.formField.email.errors.required')
    ),
  });

  useEffect(() => {
    const sessionExpiredInterval = setTimeout(() => {
      // handleSessionExpired();
    }, 600000); // 10 min

    return () => {
      clearTimeout(sessionExpiredInterval);
    };
  }, [handleSessionExpired]);

  const handleApiRequestError = useCallback(
    (error: AxiosError<ApiErrorData>, resetForm: () => void) => {
      const data = error.response?.data
        ? error.response.data
        : { details: i18n.t('Global.modals.errorCommunicatingServer.body') };

      logInfo('adc-webview:signin', { status: error.status, data });

      switch (error.status) {
        case ApiStatus.UNAUTHORIZED: {
          const code = error.response?.data.code;

          if (code === ApiStatus.FORBIDDEN_HCP_ACCOUNT) {
            showHcpError();
            return;
          }

          handleLoginError(error, resetForm);
          break;
        }

        case ApiStatus.UNSUPPORTED_COUNTRY:
          handleUnsupportedCountryError();
          break;

        case ApiStatus.TOO_MANY_REQUESTS: {
          handleLockoutError(error);
          resetForm();
          break;
        }

        default:
          showErrorPage();
      }
    },
    [
      handleLockoutError,
      handleLoginError,
      handleUnsupportedCountryError,
      showErrorPage,
      showHcpError,
    ]
  );

  const signInRequest = useCallback(
    async (formValues: MyFormValues, resetForm: () => void) => {
      try {
        setIsLoading(true);

        const { email, password } = formValues;

        const authSession = await signIn(email, password, app, cid);

        const redirectUrl = await verifyCid(cid, country, redirect_uri, authSession.account_id);

        redirect(redirectUrl);
      } catch (err) {
        const error = err as AxiosError<ApiErrorData>;

        handleApiRequestError(error, resetForm);
      } finally {
        setIsLoading(false);
      }
    },
    [app, cid, country, handleApiRequestError, redirect, redirect_uri]
  );

  const onSubmit = useCallback(
    async (values: MyFormValues, { setSubmitting, resetForm }: FormikHelpers<MyFormValues>) => {
      signInRequest(values, resetForm).finally(() => {
        setSubmitting(false);
      });
    },
    [signInRequest]
  );

  const handleClickForgotPass = useCallback(() => {
    mediator.publish('router:navigate', '/forgot-password');
  }, []);

  const handleClickCreateAccount = useCallback(() => {
    mediator.publish('router:navigate', '/create-account');
  }, []);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    isValid,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    initialErrors: {
      email: i18n.t<string>(
        'Login.content.signInForm.formField.email.errors.emailAddressValidEmail'
      ),
      password: i18n.t<string>('Login.content.signInForm.formField.email.errors.required'),
    },
  });

  return (
    <Main>
      <LogoActionBar title={i18n.t<string>('Login.title')} noActionButton />
      {isLoading && <Spinner testID="Auth.spinner" />}
      <ScrollContainer>
        <Header
          titleTestID="Auth.signInTitle"
          subtitleTestID="Auth.useFreestyleAccount"
          title={i18n.t<string>('Login.title')}
          subtitle={i18n.t<string>('Login.subtitle', {
            appName: i18n.t<string>('Global.appNames.libreView'),
          })}
        />
        <Banner message={lockoutMessage} submessage={lockoutSubmessage} />
        <Form>
          <Input
            aria-label={i18n.t<string>('Login.content.signInForm.formField.email.label')}
            placeholder={i18n.t<string>('Login.content.signInForm.formField.email.placeholder')}
            label={i18n.t<string>('Login.content.signInForm.formField.email.label')}
            errorMessage={errors.email}
            isInvalid={errors.email && touched.email ? true : false}
            value={values.email}
            testID="email"
            onChangeText={handleChange('email')}
            onBlur={handleBlur('email')}
            inputMode="email"
            disabled={isLocked}
          />
          <InputPassword
            placeholder={i18n.t<string>('Login.content.signInForm.formField.password.placeholder')}
            label={i18n.t<string>('Login.content.signInForm.formField.password.label')}
            errorMessage={errors.password}
            testID="password"
            setFieldValue={setFieldValue}
            onBlur={handleBlur('password')}
            onChangeText={handleChange('password')}
            value={values.password}
            isEyeIconVisible={!isLocked}
            isInvalid={errors.password && touched.password ? true : false}
            isDisabled={isLocked}
          />
          <Link
            style={{ marginTop: 10 }}
            testID="Auth.forgotPassword"
            aria-label={i18n.t<string>('Login.content.signInForm.secondaryText')}
            IconElement={IconQuestionmark}
            onPress={handleClickForgotPass}
            disabled={isLocked}
          >
            {i18n.t<string>('Login.content.signInForm.secondaryText')}
          </Link>
        </Form>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>('Login.title')}
        buttonTestID="Auth.signIn"
        buttonLinkTitle={`Don't have an account?`}
        buttonLinkTitleTestID="Auth.doNotHaveAccount"
        buttonLinkText={`Create Account`}
        buttonLinkTestID="Auth.createAccount"
        onButtonLinkClick={handleClickCreateAccount}
        onButtonSubmit={handleSubmit}
        isButtonDisabled={isLocked || !isValid}
        visible={!isLoading}
      />
    </Main>
  );
};

export default connector(Login);
