import React from 'react';

import { Divider, HStack, Text, VStack } from '@adc/parallax-component-library';
import { IconBack } from '@adc/parallax-icons';

import { usePartner } from 'Hooks';

import { IconButton } from 'Components/utility';

import i18n from 'Utilities/i18n';

const ActionBarHeader: React.FC = () => {
  const { partner } = usePartner();

  return (
    <VStack>
      <HStack alignItems="center" padding="$2">
        <IconButton
          testID="Partner.action-back-btn"
          marginRight="$2"
          onPress={() => window.location.replace(partner.website)}
        >
          <IconBack
            testID="Partner.action-back-btn-icon"
            size="small"
            color="$interactive.tertiary.enabled.text"
          />
        </IconButton>
        <Text
          testID="Partner.action-back-btn-text"
          fontSize="$bodySmall.default"
          color="$interactive.tertiary.enabled.text"
        >
          {i18n.t('Login.content.returnPartnerHeader.bodyText', {
            partnerName: partner.displayName,
          })}
        </Text>
      </HStack>
      <Divider marginTop="$2" borderColor="$neutral.40" />
    </VStack>
  );
};

export { ActionBarHeader };
